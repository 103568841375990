/* TODO: rename to hidden */
.disabled {display:none !important}

/* main page */
:root {--control-radius:3px}
html {background:black; color:white; font-family:sans-serif}
body {font-size:85%; display:flex; flex-direction:column; align-items:center; height:100vh; margin:0}
/* https://meyerweb.com/eric/thoughts/2010/02/12/fixed-monospace-sizing/ */
code, #code-editor, #code-editor .cm-scroller {font-family:"",monospace}

#content {display:flex; flex-direction:column; height:100%}
@media (min-width:1540px) { /* 1024 (canvas) + 4 (outline) + 512 (library) */
	/* override js window resize event while still letting .container-fixed inherit max-width */
	html:not([data-embed]) #content {flex-direction:row; min-width:100%}
}

.container-fixed {
	display:flex; flex-direction:column; flex-shrink:0;
	box-sizing:border-box; max-width:inherit; max-height:100%}
html[data-embed] .container-fixed {height:100%}

/* code editor */
#code-editor-container {
	display:block; box-sizing:border-box; width:100%; height:256px;
	border:2px solid white; overflow:hidden; resize:vertical}
@media (min-width:1540px) { /* 1024 (canvas) + 4 (outline) + 512 (library) */
	/* override resize handle */
	#code-editor-container {resize:none; height:100% !important}
}
html[data-embed] #code-editor-container {resize:none; height:100%}

#code-editor {width:100%; height:100%; line-height:1; font-size:1rem}
#code-editor:not(.cm-editor) {
	word-break:break-all; white-space:break-spaces; tab-size:3;
	margin:0; border:none; outline:none;
	background:#0000; resize:none}

#code-editor .cm-scroller {padding:2px; line-height:1}
#code-editor .cm-activeLine {background-color:transparent}
#code-editor .cm-cursor {border-left-color:white}
#code-editor .cm-content, #code-editor .cm-line {padding:0}
#code-editor .cm-matchingBracket {
	background-color:transparent; border:1px solid grey; margin:-1px}
#code-editor .cm-selectionMatch {background-color:#333; position:relative; z-index:-1}

/* editor highlight style */
#code-editor, #code-editor .cm-content {color:#eee; caret-color:#eee}
.tok-keyword {color:#e8e}
.tok-string {color:#fa7}
.tok-number {color:#ada}
.tok-variableName {color:#9de}
.tok-propertyName {color:#eea}
.tok-comment {color:#5c4}
.tok-string2 {color:#f78}
.tok-invalid {text-decoration:wavy #f66e underline}

/* playback controls */
button, input {font-size:100%}
#controls, .control-group {
	display:flex; flex-direction:row; flex-wrap:wrap;
	justify-content:center; align-items:center}
#controls {gap:2px .5rem; margin:2px 0}
#controls :not(.text) {height:1.8rem; box-sizing:border-box; margin:0}
#controls button {width:1.8rem; padding:0; display:inline-block}

#controls :is(button, input, select) {border-radius:0}
#controls :is(button:first-child, input:first-child, select:first-child, .control-round-left) {
	border-top-left-radius:var(--control-radius);
	border-bottom-left-radius:var(--control-radius)}
#controls :is(button:last-child, input:last-child, select:first-child, .control-round-right) {
	border-top-right-radius:var(--control-radius);
	border-bottom-right-radius:var(--control-radius)}

#controls[data-time-controls-disabled=true] .control-time,
#controls[data-playback-controls-disabled=true] .control-playback,
#controls[data-view-controls-disabled=true] .control-view,
#controls[data-song-controls-disabled=true] .control-song {
	display:none !important}

/* scope */
#canvas-container {
	display:flex; position:relative;
	box-sizing:border-box; border:2px solid #fc4}
#canvas-container:not([data-disabled]) {cursor:pointer}

#canvas-main {width:100%; height:auto}

#canvas-timecursor {position:absolute; top:0; bottom:0; width:2px; background:red}

#canvas-toggleplay {
	display:none; position:absolute;
	top:50%; left:50%;
	padding:8px 18px;
	background-color:#0bf6;
	border-radius:16px;
	line-height:0;
	transform:translate(-50%,-50%)}
#canvas-container:hover > #canvas-toggleplay {
	display:block; background-color:#0bf8}
#canvas-toggleplay.canvas-toggleplay-show {display:block}
#canvas-toggleplay > svg {width:46px; height:46px; fill:currentColor}
#canvas-toggleplay.canvas-toggleplay-pause > #svg-canvas-toggleplay-play,
#canvas-toggleplay:not(.canvas-toggleplay-pause) > #svg-canvas-toggleplay-pause {
	display:none}

/* status message */
#error {word-break:break-all}
#error[data-err-type="compile"] {color:#f67}
#error[data-err-type="firstrun"] {color:#fa6}
#error[data-err-type="runtime"] {color:#fc4}

/* text */
.container-scroll {overflow-y:auto; flex-grow:1}
@media (min-width:1540px) { /* 1024 (canvas) + 4 (outline) + 512 (library) */
	/* TODO: reorganize to merge identical media queries */
	.container-scroll {padding-left:0.8rem}
}

details > :not(summary) {margin-inline-start:1em}

figure.figure-list {margin-inline:0}

ul {padding:0; margin:0; margin-inline-start:1em}

#library li {margin-block-end:0.6em}

button.code-button {
	display:inline; padding:0; border:none; outline:inherit; text-align:inherit;
	background:none; color:#fc4; cursor:pointer; user-select:text; font-size:90%}
button.code-button:focus-visible {outline:1px dotted}
code {word-break:break-all; white-space:break-spaces}
.library-song-info {color:grey; cursor:default}

summary {cursor:pointer; font-weight:bold; margin-block:.4em}
summary > :is(h1,h2,h3,h4,h5,h6) {display:inline}
h1 {font-size:1.3em}
h2 {font-size:1.1em}
@supports (:has(+ a)) {
	summary::marker:has(+ h1) {font-size:1.3em}
	summary::marker:has(+ h2) {font-size:1.1em}
}
@supports not (:has(+ a)) {
	summary::marker {font-size:1.1em}
}

a, a:visited {color:#88d2fb}
a:hover, button.code-button:hover, summary:hover {color:#ed88fb}
